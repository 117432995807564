<div class="sign-up-bg">
  <div class="app-layout-outer">
    <div class="inner-content login-form">
      <div class="section-title">
        <h1 class="subsection-heading centered">Załóż nowe konto</h1>
      </div>
      <form nz-form [formGroup]="validateForm" class="">
        <nz-form-item>
          <nz-form-control [nzErrorTip]="nameErrorTpl">
            <input
              type="text"
              nz-input
              formControlName="firstName"
              placeholder="Imię"
            />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control [nzErrorTip]="nameErrorTpl">
            <input
              type="text"
              nz-input
              formControlName="lastName"
              placeholder="Nazwisko"
            />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control [nzErrorTip]="nameErrorTpl">
            <input
              type="mail"
              nz-input
              formControlName="email"
              placeholder="Email"
            />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control [nzErrorTip]="nameErrorTpl">
            <input
              type="password"
              nz-input
              formControlName="password"
              placeholder="Hasło"
            />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control [nzErrorTip]="nameErrorTpl">
            <input
              type="password"
              nz-input
              formControlName="passwordrepeat"
              placeholder="Powtórz hasło"
            />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control nzErrorTip="To pole jest wymagane!">
            <nz-input-group>
              <label
                nz-checkbox
                formControlName="regulationaccept"
                for="regulationaccept"
              >
                <span>
                  <span class="regulationsHeading">Akceptuję regulaminy:</span>
                  <a
                    #rulesLink1
                    class="linkToRegulations"
                    href="https://static.mpdm.com.pl/documents/regulamin-wyscig-spolecznosci-pescp-sezon-5.pdf"
                    target="_blank"
                    (click)="openInNewTab(rulesLink1)"
                  >
                    Porsche Esports Sprint Challenge Poland Sezon 5 - Wyścig
                    Społeczności
                  </a>
                  <a
                    #rulesLink2
                    class="linkToRegulations"
                    href="https://static.mpdm.com.pl/documents/regulamin-pescp-sezon-5.pdf"
                    target="_blank"
                    (click)="openInNewTab(rulesLink2)"
                  >
                    Porsche Esports Sprint Challenge Poland Sezon 5
                  </a>
                </span>
              </label>
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control nzErrorTip="To pole jest wymagane!">
            <nz-input-group>
              <label
                nz-checkbox
                formControlName="privacyaccept"
                for="privacyaccept"
              >
                <span>
                  Zapoznałem się z&nbsp;<a
                    class="linkToRegulations inline"
                    target="_blank"
                    href="https://static.mpdm.com.pl/documents/polityka-prywatnosci.pdf"
                  >
                    polityką prywatności
                  </a>
                  i&nbsp;wyrażam zgodę na przetwarzanie moich danych osobowych
                  przez Ragnar Simulator Sp. z o.o. w celu organizacji Porsche
                  Esports Sprint Poland Challenge 2024.
                </span>
              </label>
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <!--
        <nz-form-item>
          <nz-form-control nzErrorTip="To pole jest wymagane!">
            <nz-input-group>
              <label nz-checkbox formControlName="contractaccept" for="contractaccept">
                <span>Zapoznałem się z&nbsp;treścią
                  <a class="linkToRegulations inline" target="_blank"
                    href="https://static.mpdm.com.pl/documents/kontrakt-pescp-2023.pdf" (click)="
                      openInNewTab(
                        'https://static.mpdm.com.pl/documents/kontrakt-pescp-2023.pdf'
                      )
                    ">
                    kontraktu promotorskiego
                  </a>
                  i w&nbsp;przypadku uczestnictwa w&nbsp;sezonie głównym Porsche
                  Esports Sprint Challenge Poland 2023 akceptuje jego warunki.
                </span>
              </label>
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      -->
        <nz-form-item>
          <nz-form-control nzErrorTip="To pole jest wymagane!">
            <nz-input-group>
              <label
                nz-checkbox
                formControlName="newsletteraccept"
                for="newsletteraccept"
              >
                <span
                  >Wyrażam zgodę na otrzymywanie informacji handlowych drogą
                  elektroniczną na podany powyżej adres poczty elektronicznej
                  wysyłanych przez Ragnar Simulator Sp. z o.o.
                </span>
              </label>
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control>
            <button
              nz-button
              nzType="primary"
              class="btn btn-blue login-form-button w-100 mb-4"
              (click)="submitForm()"
            >
              Zarejestruj się
            </button>
            <p class="maszKonto">Posiadasz już konto?</p>
            <a [routerLink]="'/sign-in'">
              <button
                nz-button
                nzType="primary"
                class="btn btn-black zalogujSie"
              >
                Zaloguj się
              </button>
            </a>
          </nz-form-control>
        </nz-form-item>
      </form>
    </div>
  </div>
</div>

<ng-template #nameErrorTpl let-control>
  <div class="d-flex flex-column">
    <span *ngIf="control.hasError('required')">
      To pole jest obowiązkowe!
    </span>
    <span *ngIf="control.hasError('requiredTrue')">
      Zaznaczenie tego pola jest obowiązkowe!
    </span>
    <span *ngIf="control.hasError('min')">
      Minimalna wartość {{ control.errors.min.min }} (wybrano:
      {{ control.errors.min.actual }})!
    </span>
    <span *ngIf="control.hasError('max')">
      Maksymalna wartość {{ control.errors.max.max }} (wybrano:
      {{ control.errors.max.actual }})!
    </span>
    <span *ngIf="control.hasError('startNumberValidator')">
      Ten numer jest już zajęty!
    </span>
    <span *ngIf="control.hasError('minlength')">
      Hasło musi mieć co najmniej
      {{ control.errors.minlength.requiredLength }} znaków!
    </span>
    <span *ngIf="control.hasError('email_taken')">
      Ten adres email jest już zajęty!
    </span>
    <span *ngIf="control.hasError('passwordsMustMatch')">
      Hasła różnią się od siebie!
    </span>
  </div>
</ng-template>
