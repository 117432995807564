<section class="results-bg">
  <div class="app-layout">
    <div class="calendar">
      <h1 class="section-heading d-inline-flex">
        <img alt="Dekorator" src="/assets/images/pescp-2024/rect-with-twist.svg" class="decorator pe-3" />
        <!--<span>Wyniki - {{ seasonName$ | async }}</span>-->
        <span>Wyniki - Baraże 2024</span>
      </h1>
      <div nz-row nzGutter="32">
        <div nz-col [nzXs]="24" [nzLg]="8" class="mb-3" *ngFor="let event of events$ | async">
          <div [routerLink]="['/race', event.id, 'results']" class="event-bar">
            <div nz-row class="event-content">
              <div class="event-name fw-bold" nz-col nzXs="24">
                {{ event.name }} - {{ event.track?.name }}
              </div>
              <div nz-col nzMd="24" nzXl="12">15 min. + 40 min.</div>
              <div class="event-date" nz-col nzMd="24" nzXl="12">
                <ng-container *ngIf="event.eventStart > today">
                  {{ event.eventStart | date : "dd.MM.yyyy" }}
                </ng-container>
                <ng-container *ngIf="event.eventStart < today && event.eventStop > today">
                  W toku
                </ng-container>
                <ng-container *ngIf="event.eventStop < today">
                  Zakończony
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box results" *ngIf="standings$ | async; let standings">
      <div class="results-table">
        <h1 class="section-heading">Klasyfikacja generalna</h1>
        <div class="d-flex flex-column flex-md-row mb-3">
          <button nz-button nzType="primary" [routerLink]="['/archive', 40]"
            class="btn btn-outlined-blue me-md-2 mb-2 mb-md-0">
            Wyniki 2023
          </button>
          <button nz-button nzType="primary" [routerLink]="['/archive', 17]"
            class="btn btn-outlined-blue mx-md-2 my-2 my-md-0">
            Wyniki 2022
          </button>
          <button nz-button nzType="primary" [routerLink]="['/archive', 7]"
            class="btn btn-outlined-blue mx-md-2 my-2 my-md-0">
            Wyniki 2021
          </button>
          <button nz-button nzType="primary" [routerLink]="['/archive', 4]"
            class="btn btn-outlined-blue ms-md-2 mt-2 mt-md-0">
            Wyniki 2020
          </button>
        </div>
        <nz-table nzTemplateMode [nzLoading]="standingsLoading$ | async" [nzPageSize]="10"
          class="championship-table mb-3" [nzScroll]="{ x: '1246px' }">
          <thead>
            <tr>
              <th [nzWidth]="'70px'">Poz.</th>
              <th [nzWidth]="'70px'">Nr</th>
              <th>Kierowca</th>
              <th></th>
              <th>Zespół</th>
              <th [nzWidth]="'100px'">Punkty</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let driver of standings; let i = index">
              <tr>
                <td>{{ i + 1 }}</td>
                <td>{{ driver.startNumber }}</td>
                <td>{{ driver.firstName }} {{ driver.lastName }}</td>
                <td>
                  <img class="car-icon" alt="Ikona auta" [src]="staticUrl + 'entrants/' + driver.icon" />
                </td>
                <td>{{ driver.teamName }}</td>
                <td>
                  {{ driver.pointsAfterDeduction }} pkt.
                </td>
              </tr>
            </ng-container>
            <ng-container *ngIf="standings.length === 0">
              <tr>
                <td class="empty-table" colspan="100%">Już niedługo...</td>
              </tr>
            </ng-container>
          </tbody>
        </nz-table>
        <div class="d-flex flex-column flex-md-row justify-content-end">
          <button nz-button nzType="primary" [routerLink]="['/archive', currentSeason]" class="btn btn-blue">
            Zobacz więcej
          </button>
        </div>
      </div>
      <div class="results-image-shape"></div>
    </div>
  </div>
</section>